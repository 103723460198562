
import optionsData from '@/data/options.json';
import bannerData from '@/data/banner.json';
export default {
  props:{
    region:{
      type:String,
      default:'UK',
    },
  },
  data(){
      return{
        options: optionsData,
        banner: bannerData
      }
    },
  computed: {
    backgroundStyles() {
      const imgUrl = this.$img('https://cdn-wp.tefl.org/wp-content/uploads/2024/06/Motorbike_hero.jpg', { width: '100%',format:'webp',quality:50 })
      return {
        backgroundImage: `url('${imgUrl}')`
      }
    },
    early() {
      const d = new Date();
      const bfDate = new Date('2023-11-24')
      if (d < bfDate){
        return true;
      } else {
        return false
      }
    },
    currentMonth() {
      const month = ["January","February","March","April","May","June","July","August","September","October","November","December"];
      const d = new Date();
      const name = month[d.getMonth()];
      return name
    },
    discount_amount(){
      const amount = this.$props.region === 'US' ? this.banner.discount_amount_us_bf : this.banner.discount_amount_bf;
      return amount
    },
    headline1(){
      const headline = this.$props.region === 'US' ? this.banner.slider_headline_1_us : this.banner.slider_headline_1;
      return headline
    },
    headline2(){
      const headline2 = this.$props.region === 'US' ? this.banner.slider_headline_2_us : this.banner.slider_headline_2;
      return headline2
    },
    subheading(){
      const subheading = this.$props.region === 'US' ? this.banner.slider_subheading_us : this.banner.slider_subheading;
      return subheading
    }
  }
}
